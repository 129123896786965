import * as React from 'react'
import {useDispatch} from "react-redux"

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'

import axios from "axios"
import {toast} from "react-toastify"

import {urlApi} from "../../../api"
import {loadingToggle} from "../../../store/actions/appActions"


import {useStyles} from '../../../themes/styles'

const DialogStripe = (props) => {
    const classes = useStyles()

  const prices = [
    [10, 0.50],
    [30, 1.00],
    [100, 2.00],
    [300, 4.00],
  ]

  const handleClose = () => {
    props.setOpen(false)
  }

  return (
    <div>
      <Dialog open={Boolean(props.open)} onClose={handleClose}>
        <DialogTitle>Buy Credits</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box textAlign="center">
              {props.ready ? (
                <stripe-pricing-table
                  pricing-table-id="prctbl_1QoZZdLTE0QJSoYKjVgKfNIL"
                  publishable-key="pk_test_51JXOm7LTE0QJSoYKZVu12GBNOLcwjDyoHkS07kgeKDFrufeGB6tG8LhszMVp7DP1fDHxmqH1iWNCoBlLLkwZJ382008mux8UBv"
                  client-reference-id={props.idUser}
                >
                </stripe-pricing-table>
              ) : (<></>)}

              {/*prices.map((step, index) => (<>
                <Button
                  className={classes.spacing}
                  variant="contained"
                  color="primary"
                  style={{width:"100%"}}
                  sx={{marginTop: "15px", marginBottom: "15px"}}
                >
                  <Box>
                    <Typography variant="h6">
                      {step[0]} Credits - ${step[1].toFixed(2)}
                    </Typography>
                  </Box>
                </Button>
              </>))*/}
            </Box>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogStripe